import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import SEO from "../components/SEO"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()

const ComponentName = ({
  data: {
    blog: {
      content: { data },
      desc,
      title,
    },
  },
}) => {
  const { content } = data

  return (
    <Layout>
      <SEO title={title} description={desc} />
      <section className="blog-template">
        <div className="section-center">
          <article className="blog-content">
            <ReactMarkdown children={content} />
          </article>
          <Link to="/blog" className="btn center-btn">
            &larr;back to blog
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    blog: strapiBlog(slug: { eq: $slug }) {
      id
      slug
      title
      desc
      content {
        data {
          content
        }
      }
      date
      image {
        localFile {
          childrenImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`

// export const query = graphql`
//   query GetSingleBlog($slug: String) {
//     blog: strapiBlog(slug: { eq: $slug }) {
//       content {
//         data {
//           content
//         }
//     }
//   }
// `

export default ComponentName
